<template>
  <div class="orderHistory">
    <order-address :site="site"></order-address>
    <in-the-lease :listData="allData"></in-the-lease>
  </div>
</template>

<script>
import orderAddress from './orderAddress'
import InTheLease from './InTheLease'
import leaseApi from "../../../utils/leaseApi";
import TEMPURL from "../../../utils/tempurl";
// import TEMPURL from "../../utils/tempurl";
export default {
  name: "orderHistory",
  components:{
    orderAddress,
    InTheLease
  },
  data(){
    return{
        site:{},
        allData:[

          ]
    }
  },
  methods: {
    async getOrders() {
      const {data} = await leaseApi.getOrders({"orderId":this.$route.params.orderId})
      console.log(data)
      let i = -1
      data.renewOrders.forEach(e=>{
         i = i+1
        this.allData.push({
          runningWaterMark:e.runningWaterMark,
          productPic:TEMPURL+e.productPic,
          createTime:e.createTime,
          expireTime:e.expireTime,
          orderMoney:e.orderMoney,
          orderStatus:6,
          productName:e.productName,
          statusStr:i==0 ? "原订单" : i==1 ? "首次续租" : i+"次续租"
        })
      })
      this.site=data.site
      // this.allData=data.renewOrders
      console.log(data.site);
    }
  },
  created() {
    this.getOrders()
  }
}
</script>


<style lang="less" scoped>
.orderHistory{
  background: #F6F6F6;
  height: 100%;
  padding-top: 0.28rem;
}

</style>
