<template>
  <div class="orderAddress">
    <div class="orderAddressContent" v-model="site">
      <img src="../../images/personalinformation.png" />
      <div class="contentTxt">
       <div>
         <div>{{ site.receiveType==0?"物流":'自提' }}</div>
         <div>{{site.recipients}}</div>
         <div>{{site.phone}}</div>
       </div>
        <div class="addressTxt">
          {{site.province }} {{site.city}} {{site.county}} {{site.detailedAddress}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderAddress",
  props:["site"],
  data(){
    return{
      address:{
        text:'物流',
        name:"皮皮虾",
        phone:'136060460374',
        address:'湖南省长沙市爱德华镇保德花园小区38号小区38 号'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.orderAddress{
  margin-bottom: 0.28rem;
  .orderAddressContent{
    background: #FFFFFF;
    border-radius:0.28rem ;
    padding: 0.26rem 0.65rem 0.33rem 0.56rem;
    display: flex;
    img{
      width: 0.89rem;
      height: 0.89rem;
      margin-right: 0.32rem;
      align-self: center;
    }
    .contentTxt{
      >div:nth-child(1){
        display: flex;
        div:nth-child(1){
          width: 0.83rem;
          height: 0.53rem;
          border: 0.01px solid #FB884F;
          border-radius: 0rem;
          font-size: 0.33rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #FB884F;
          text-align: center;
          line-height: 0.53rem;
          margin-right: 0.13rem;
          align-self: center;
        }
        div:nth-child(2){
          font-size: 0.42rem;
          font-family: xiaocheng;
          font-weight: 500;
          color: #333333;
          margin-right: 0.24rem;
          align-self: center;
        }
        div:nth-child(3){
          font-size: 0.33rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #666666;
          align-self: center;
        }
      }
      .addressTxt{
        font-size: 0.36rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #666666;
        line-height: 0.43rem;
        margin-top: 0.15rem;
      }
    }
  }
}
</style>
